import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';

export const useAuthenticatedUser = () => {
  const [ user, setUser ] = useState<any|undefined>();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
    .then(user => setUser(user))
    .catch(err => {
      console.error(err);
      setUser(undefined);
    });
  }, []);

  return user;
};