import React from 'react';

interface App {
  name: string;
  url: string;
  imageFilename: string;
}

const APPS: App[] = [
  { name: 'RSS', url: 'http://rss.jlcdk.com', imageFilename: 'FreshRSS.png' },
  { name: 'Samwise', url: 'https://samwise.jlcdk.com', imageFilename: 'samwise.jpg' },
];

export const AppsList = (): JSX.Element => {
  return <div className="apps-list">{APPS.map(app => <AppCard app={app} key={app.name}/>)}</div>;
}

interface AppCardProps {
  app: App;
}

export const AppCard = (props: AppCardProps): JSX.Element => {
  return <div>
    <a href={props.app.url}>
      <div className="app-card">
        <img src={`/apps/${props.app.imageFilename}`} alt={props.app.name} />
        <span className="app-name">{props.app.name}</span>
      </div>
    </a>
  </div>;
}