import React from 'react';
import Amplify, { Auth } from 'aws-amplify';

import amplifyConfig from './config/amplify';
import { AppsList } from './components/AppsList';
import { useAuthenticatedUser } from './hooks/amplifyHooks';
import './App.scss';

Amplify.configure(amplifyConfig);

const App = () => {
  const user = useAuthenticatedUser();

  return (
    <div className="App">
      <h1>Portal</h1>
      {user ? <AppsList /> : <button onClick={() => Auth.federatedSignIn()}>Open Hosted UI</button>}
    </div>
  );
}

export default App;
