const amplifyConfig = {
  Auth: {
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_Uzk6jZkuE',
    userPoolWebClientId: '6te891mes1plaov58er4p0v28b',
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: 'jlcdk.com',
      // OPTIONAL - Cookie path
      path: '/',
      expires: 365,
      // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      // sameSite: "strict",
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: false
    },
    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: 'auth.jlcdk.com',
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: 'https://jlcdk.com',
      redirectSignOut: 'https://jlcdk.com',
      responseType: 'token' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  }
}

export default amplifyConfig;